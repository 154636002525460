import React from "react";
import PropTypes from "prop-types";
import Arrow from "~components/Arrow";

const TicketButton = ({ bordered, className, color, ticketUrl }) => {
  let borderStyle = `2px solid transparent`;

  if (bordered) {
    borderStyle = `2px solid ${color}`;
  }

  return (
    <>
      {ticketUrl && ticketUrl !== `` && (
        <a href={ticketUrl} target="_blank" rel="noopener noreferrer">
          <button
            type="button"
            className={`ticket-button ${
              color === `#000000` || color === `black`
                ? `ticket-button--black`
                : ``
            } ${className} relative flex items-center justify-start py-2 px-3`}
            style={{ border: borderStyle, color }}
          >
            <span className="f5 uppercase">TICKETS</span>

            <Arrow color={color} />
          </button>
        </a>
      )}
    </>
  );
};

TicketButton.defaultProps = {
  bordered: false,
  className: ``,
  color: `#000000`,
  ticketUrl: ``
};

TicketButton.propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  ticketUrl: PropTypes.string
};

export default TicketButton;
