/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import EventLayout from "~components/EventLayout";
import Layout from "~components/Layout";
import PastEvents from "~components/PastEvents";
import SEO from "~components/SEO";

const EventPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { draft } = frontmatter;

  return (
    <>
      {(draft && <SEO noindex />) || (
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />
      )}

      <Layout className="event-page relative">
        <EventLayout event={frontmatter} />

        <PastEvents />
      </Layout>
    </>
  );
};

export default EventPage;

export const query = graphql`
  query EventPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        artist
        location
        draft
        onSale
        
        ticketUrls {
          region
          url
        }

        ticketUrl
        timezone
        endDate
        times
        color
        fontColor
        description
        detailsFilm
        detailsMusic
        detailsMusicImage {
          childImageSharp {
            fluid(maxWidth: 768, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        quote
        quoteAuthor
        image {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        carouselImages {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryUpperLeft {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryUpperRight {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryDisplacementMap {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryMiddleRight {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryLowerLeft {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryLowerRight {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        videoUpper
        videoLower
      }
    }
  }
`;
