import React from "react";
import PropTypes from "prop-types";

const Star = props => (
  <svg className={props.className} viewBox="0 0 40 38" fill="none">
    <path
      d="M24.8148 13.7326L39.4547 14.0456L28.1329 23.3931L32.3626 37.5082L20.0361 29.5552L8.01341 37.9673L11.7148 23.7026L0.0546875 14.7863L14.6704 13.9239L19.4871 0L24.8148 13.7326Z"
      fill="black"
    />
  </svg>
);

export default Star;

Star.defaultProps = {
  className: ``
};

Star.propTypes = {
  className: PropTypes.string
};
