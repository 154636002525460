/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import anime from "animejs";
import isNode from "detect-node";
import { DocumentContext } from "~context/DocumentContext";
import { ieDetector } from "~utils/screen";

import defaultDisplacementMap from "~assets/images/webgl/default-displacement.jpg";

// https://github.com/pixijs/pixi.js/issues/3224#issuecomment-295673090

const ScrollDistorter = ({ className, displacementMap, image }) => {

  //----------------------------------------------------------------------------
  //
  // consts

  const { scrollTop, windowHeight, windowWidth } = useContext(DocumentContext);
  const containerRef = useRef();
  const [activeAnime, setActiveAnime] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [displacer, setDisplacer] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [pixiApp, setPixiApp] = useState(null);
  const [pixiSprite, setPixiSprite] = useState(null);
  const [PIXI, setPIXI] = useState(null);
  const [started, setStarted] = useState(false);
  const [visible, setVisible] = useState(true);

  //----------------------------------------------------------------------------
  //
  // functions

  const animeReset = () => {
    if (!activeAnime || !displacer || !loaded || !started) {
      return;
    }

    anime.remove(displacer);

    setActiveAnime(null);
  };

  const start = (loader, resources) => {
    if (isNode) {
      return;
    }

    const newPixiApp = new PIXI.Application({
      width: canvas.width,
      height: canvas.height,
      transparent: false
    });

    containerRef.current.appendChild(newPixiApp.view);

    //

    const container = new PIXI.Container();
    const background = new PIXI.Sprite(resources.backgroundImage.texture);

    background.width = canvas.width;
    background.height = canvas.height;

    const displacementSprite = new PIXI.Sprite(
      resources.displacementMap.texture
    );

    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

    const displacementFilter = new PIXI.filters.DisplacementFilter(
      displacementSprite
    );

    container.filters = [displacementFilter];
    container.addChild(background);
    container.addChild(displacementSprite);

    newPixiApp.stage.addChild(container);

    setDisplacer(displacementFilter);
    setPixiApp(newPixiApp);
    setPixiSprite(background);
  };

  //----------------------------------------------------------------------------
  //
  // hook - DOM ref

  useEffect(() => {
    if (containerRef.current && !loaded && window) {
      setLoaded(true);

      let pixi;

      if (typeof window !== `undefined`) {
        // eslint-disable-next-line global-require
        pixi = require(`pixi.js`);
        pixi.utils.skipHello();
      }

      setPIXI(pixi);
    }
  }, [containerRef.current]);

  //----------------------------------------------------------------------------
  //
  // hook - PIXI

  useEffect(() => {
    if (!PIXI) {
      return;
    }

    const canvasBoundingRect = containerRef.current.getBoundingClientRect();
    const { width, height } = canvasBoundingRect;

    let displacementSrc = defaultDisplacementMap;

    if (displacementMap) {
      displacementSrc = displacementMap;
    }

    setCanvas({
      width,
      height,
      displacementSrc,
      image
    });
  }, [PIXI]);

  //----------------------------------------------------------------------------
  //
  // hook - canvas

  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (!started) {
      setStarted(true);

      const pixiLoader = new PIXI.Loader();

      pixiLoader
        .add(`displacementMap`, canvas.displacementSrc)
        .add(`backgroundImage`, canvas.image)
        .load(start);
    } else if (pixiApp) {
      pixiApp.renderer.resize(canvas.width, canvas.height);
      pixiSprite.width = canvas.width;
      pixiSprite.height = canvas.height;
    }
  }, [canvas]);

  //----------------------------------------------------------------------------
  //
  // hook - window resize

  useEffect(() => {
    if (!containerRef || !containerRef.current || !canvas || !visible) {
      return;
    }

    const containerBoundingRect = containerRef.current.getBoundingClientRect();
    const { width, height } = containerBoundingRect;

    setCanvas({
      ...canvas,
      width,
      height
    });
  }, [windowHeight, windowWidth]);

  //----------------------------------------------------------------------------
  //
  // hook - scroll

  useEffect(() => {
    if (
      !containerRef ||
      !containerRef.current ||
      !displacer ||
      !loaded ||
      !started
    ) {
      return;
    }

    const { height, top } = containerRef.current.getBoundingClientRect();

    if (scrollTop > top && top > -height) {
      if (!visible) {
        setVisible(true);
      }

      let scrollValue = 0;

      scrollValue = (windowHeight - top) / windowHeight;

      if (scrollValue > 1) {
        scrollValue = 1;
      } else if (scrollValue < 0) {
        scrollValue = 0;
      }

      scrollValue = 1 - scrollValue;

      setActiveAnime(
        anime({
          targets: displacer.scale,
          x: scrollValue * 500,
          y: scrollValue,
          delay: 0,
          duration: 0
        })
      );
    } else if (visible) {
      if (!visible) {
        setVisible(false);
        animeReset();
      }
    }
  }, [scrollTop]);

  //----------------------------------------------------------------------------
  //
  // DOM output


  const ie = ieDetector();

  if (typeof window === `undefined` || (ie && ie > 10)) {
    return (
      <div className={`${className} w-full h-full absolute`}>
        <img
          className="w-full absolute transform-center"
          src={image}
          alt="Scroll Distorter Banner"
        />
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      role="presentation"
      className={`scroll-distorter ${className} relative`}
    ></div>
  );
};

export default ScrollDistorter;
