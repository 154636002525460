/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import Glide from "@glidejs/glide";
import Img from "gatsby-image";
import Plx from "react-plx";
import moment from "moment-timezone";
import { AppContext } from "~context/AppContext";
import ScrollDistorter from "~components/ScrollDistorter";
import SideText from "~components/SideText";
import Star from "~components/Star";
import TicketButton from "~components/TicketButton";
import TicketsButton from "~components/TicketsButton";
import TicketModal from "~components/TicketModal";

import quotes from "~assets/images/icons/quotes.svg";

class EventLayoutComponent extends Component {
  glide;

  glideRef = React.createRef();

  glideRetries = 0;

  skewRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      this.glideMount(true);
    });
  }

  glideMount = retry => {
    if (this.glide) {
      return;
    }

    if (!this.glideRef || !this.glideRef.current) {
      if (retry) {
        this.glideRetries += 1;

        setTimeout(() => {
          this.glideMount(this.glideRetries < 3);
        }, 1000);
      }

      return;
    }

    this.glide = new Glide(`.event-page__glide`, {
      autoplay: 5000,
      hoverpause: false,
      gap: 0,
      perView: 1,
      type: `carousel`
    }).mount();
  };

  //

  getSkewRotate = ref => {
    if (!ref || !ref.current) {
      return `rotateX(0) rotateY(0)`;
    }

    const { windowHeight } = this.props.appContext;
    const { top, height } = ref.current.getBoundingClientRect();

    const startThreshold = -height;
    const endThreshold = windowHeight + height / 2;

    if (top > startThreshold && top < endThreshold) {
      const rotationTarget = height / 4 - top;

      let rotate = 0;

      if (rotationTarget < 0) {
        rotate = 0 - rotationTarget / 25;
      }

      return `rotateX(${rotate}deg) rotateY(${rotate / 2}deg)`;
    }

    return `rotateX(0) rotateY(0)`;
  };

  //

  render() {
    const { appContext, event } = this.props;
    const { device } = appContext;
    const allTimes = event.times.split(`,`);
    const eventTimes = [];
    const parallaxDuration = appContext.windowHeight * 1.1 || 1;

    let eventStartDate;

    allTimes.forEach(time => {
      const eventTime = moment.tz(`${time}`, `D/M/YYYY HH:mm`, event.timezone);

      if (!eventStartDate) {
        eventStartDate = eventTime;
      }

      eventTimes.push(eventTime);
    });

    let displacementMap;

    if (
      event.galleryDisplacementMap &&
      event.galleryDisplacementMap.childImageSharp
    ) {
      displacementMap = event.galleryDisplacementMap.childImageSharp.fluid.src;
    }

    //

    return (
      <>
        {/* {event?.ticketUrls?.[0] && (
          <TicketModal urls={event.ticketUrls} />
        )} */}

        <section className="event-page__banner w-full relative gpu">
          <div className="w-full h-screen absolute top-0 right-0 bottom-0 left-0">
            <SideText
              color={event.fontColor}
              textLeft={(event.onSale && `ON SALE NOW`) || `PAST EVENT`}
            />
          </div>

          <div className="event-page__banner__inner w-full h-full relative pt-32">
            <div
              className="w-full h-screen absolute top-0 right-0 bottom-0 left-0"
              style={{ backgroundColor: event.color }}
            ></div>

            <h1
              className={`event-page__banner__text animation-appear f3 relative z-10 text-center uppercase text-${event.fontColor}`}
              style={{ animationDelay: `200ms` }}
            >
              {event.title}
            </h1>

            <h2
              className={`event-page__banner__text animation-appear mt-4 relative z-10 f3 text-center text-${event.fontColor}`}
              style={{ animationDelay: `300ms` }}
            >
              <span>&#47;&#47; </span>
              <span>{event.artist}</span>
            </h2>

            {event.image && event.image.childImageSharp && (
              <div
                className="event-page__banner__image animation-appear relative grid"
                style={{ animationDelay: `500ms` }}
              >
                <Plx
                  className="grid-end-8 sm:grid-end-12 grid-start-3 sm:grid-start-1 relative block"
                  parallaxData={[
                    {
                      start: `self`,
                      duration: parallaxDuration,
                      properties: [
                        {
                          startValue: 0,
                          endValue: appContext.windowWidth * -0.1 || 1,
                          property: `translateY`
                        }
                      ]
                    }
                  ]}
                >
                  <Img
                    className="w-full relative block"
                    fluid={event.image.childImageSharp.fluid}
                    style={{ animationDelay: `200ms` }}
                    alt={event.title}
                  />
                </Plx>
              </div>
            )}
          </div>
        </section>

        <section className="event-page__details w-full relative z-10 flex items-center justify-center pt-24 xs:pt-0 pb-32 xs:pb-12 bg-white">
          <div className="grid">
            <div className="grid-end-3 sm:grid-end-10 grid-start-3 sm:grid-start-2 relative">
              <article className="event-page__details__inner relative">
                <div className="event-page__details__times relative">
                  <div
                    className="absolute top-0 right-0 flex items-center justify-center"
                    style={{ width: 64 }}
                  >
                    <h3
                      className="event-page__details__year f4"
                      style={{ transform: `rotate(-90deg)` }}
                    >
                      {eventStartDate.year()}
                    </h3>
                  </div>

                  <h2>
                    <span className="f4">{eventStartDate.date()}</span>
                    <span className="ml-2 b3 font-bold uppercase">
                      {eventStartDate.format(`MMM`)}
                    </span>
                  </h2>

                  <div className="event-page__details__time relative flex flex-wrap">
                    {eventTimes.map((time, index) => {
                      const timeIndex = index;

                      return (
                        <h3 key={timeIndex}>
                          {timeIndex !== 0 && (
                            <span className="mx-1 f5">&amp;</span>
                          )}

                          <span className="f4">{time.format(`h`)}</span>

                          <span className="b3 font-bold">
                            {time.format(`A`)}
                          </span>
                        </h3>
                      );
                    })}
                  </div>
                </div>

                <p className="mt-6 f5 uppercase">{event.location}</p>

                {event?.onSale && event?.ticketUrls && (
                  <TicketsButton
                    bordered
                    color="#000000"
                    className="mt-8"
                    urls={event?.ticketUrls}
                  />
                )}

                {/* {event.onSale && event.ticketUrl && event.ticketUrl !== `` && (
                  <TicketButton
                    bordered
                    color="#000000"
                    className="mt-8"
                    ticketUrl={event.ticketUrl}
                  />
                )} */}
              </article>
            </div>

            <div
              className="event-page__details__content grid-end-6 sm:grid-end-10 grid-start-6 sm:grid-start-2 f5"
              dangerouslySetInnerHTML={{ __html: event.description }}
            ></div>
          </div>
        </section>

        {event.videoUpper && event.videoUpper !== `` && (
          <>
            {(device === `desktop` && (
              <section className="grid">
                <div className="grid-end-8 grid-start-3 mb-32">
                  <div className="event-page__youtube w-full relative">
                    <div
                      dangerouslySetInnerHTML={{ __html: event.videoUpper }}
                    ></div>
                  </div>
                </div>
              </section>
            )) || (
              <section className="w-full relative mb-24">
                <div className="event-page__youtube w-full relative">
                  <div
                    dangerouslySetInnerHTML={{ __html: event.videoUpper }}
                  ></div>
                </div>
              </section>
            )}
          </>
        )}

        {event.carouselImages && event.carouselImages.length > 0 && (
          <section className="event-page__carousel w-full relative flex items-start gpu pb-48">
            <div
              ref={this.glideRef}
              className="event-page__glide glide relative"
            >
              <div className="glide__track relative" data-glide-el="track">
                <ul className="glide__slides relative">
                  {event.carouselImages.map((carouselImage, index) => {
                    const gallerIndex = index;

                    return (
                      <li
                        key={`carousel-item-${gallerIndex}`}
                        className="relative block"
                      >
                        <div className="event-page__glide__container relative">
                          <Img
                            className="w-full relative block"
                            fluid={carouselImage.childImageSharp.fluid}
                            alt={`Carousel Item ${gallerIndex}`}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div
                className="glide__bullets relative flex justify-end pt-6"
                data-glide-el="controls[nav]"
              >
                {event.carouselImages.map((image, index) => {
                  const buttonIndex = index;

                  return (
                    <button
                      key={`glide-button-${buttonIndex}`}
                      type="button"
                      className="glide__bullet relative block ml-4"
                      data-glide-dir={`=${buttonIndex}`}
                    >
                      <span className="opacity-0">{buttonIndex}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </section>
        )}

        <section className="event-page__distortion w-full relative flex items-start gpu">
          {event.galleryUpperLeft && event.galleryUpperLeft.childImageSharp && (
            <div
              className="event-page__distortion__image event-page__distortion__image--0 w-1/2 relative block gpu"
              style={{ perspective: `70vw` }}
            >
              <div
                ref={this.skewRef}
                className="w-full relative block"
                style={{ transform: this.getSkewRotate(this.skewRef) }}
              >
                <Img
                  className="w-full relative block"
                  fluid={event.galleryUpperLeft.childImageSharp.fluid}
                  alt={event.title}
                />
              </div>
            </div>
          )}

          {device &&
            event.galleryUpperRight &&
            event.galleryUpperRight.childImageSharp && (
              <Plx
                className="event-page__distortion__image event-page__distortion__image--1 w-1/2 relative block mt-48 gpu"
                parallaxData={[
                  {
                    start: `self`,
                    duration: parallaxDuration,
                    properties: [
                      {
                        startValue: 0,
                        endValue: appContext.windowWidth * -0.1 || 1,
                        property: `translateY`
                      }
                    ]
                  }
                ]}
              >
                <ScrollDistorter
                  className="w-full block"
                  image={event.galleryUpperRight.childImageSharp.fluid.src}
                  displacementMap={displacementMap}
                />
              </Plx>
            )}
        </section>

        <section className="relative grid">
          <div className="grid-end-3 sm:grid-end-10 grid-start-3 sm:grid-start-2">
            <article className="relative">
              {event.quotationMarks && event.quotationMarks === true && (
                <img
                  className="index-page__quotes absolute top-0 left-0"
                  src={quotes}
                  alt="Quotation marks"
                />
              )}

              {event?.quote && (
                <div
                  className="f5"
                  dangerouslySetInnerHTML={{
                    __html: `${event.quote}${
                      event?.quotationMarks && event?.quotationMarks === true
                        ? `”`
                        : ``
                    }`
                  }}
                ></div>
              )}

              {event?.quoteAuthor !== `` && (
                <>
                  <h3 className="mt-4 f5 font-bold">{event.quoteAuthor}</h3>

                  {event?.quotationStars && (
                    <div className="relative flex mt-2">
                      <Star className="event-page__star" />
                      <Star className="event-page__star" />
                      <Star className="event-page__star" />
                      <Star className="event-page__star" />
                      <Star className="event-page__star" />
                    </div>
                  )}
                </>
              )}

              {event.onSale && event.ticketUrl && event.ticketUrl !== `` && (
                <TicketButton
                  bordered
                  color="#000000"
                  className="mt-8"
                  ticketUrl={event.ticketUrl}
                />
              )}
            </article>
          </div>

          {event.galleryMiddleRight &&
            event.galleryMiddleRight.childImageSharp && (
              <Plx
                className="event-page__gallery-item event-page__gallery-item--mr grid-end-6 sm:grid-end-12 grid-start-7 sm:grid-start-1 relative block mt-64"
                parallaxData={[
                  {
                    start: `self`,
                    duration: parallaxDuration,
                    properties: [
                      {
                        startValue: 0,
                        endValue: appContext.windowWidth * -0.15 || 1,
                        property: `translateY`
                      }
                    ]
                  }
                ]}
              >
                <Img
                  className="w-full relative block"
                  fluid={event.galleryMiddleRight.childImageSharp.fluid}
                  alt={event.title}
                />
              </Plx>
            )}
        </section>

        <section className="event-page__description relative grid pb-24">
          {event.galleryLowerLeft && event.galleryLowerLeft.childImageSharp && (
            <Plx
              className="event-page__gallery-item event-page__gallery-item--ll grid-end-3 sm:grid-end-12 relative block mt-8 md:mt-24"
              parallaxData={[
                {
                  start: `self`,
                  duration: parallaxDuration,
                  properties: [
                    {
                      startValue: 0,
                      endValue: appContext.windowWidth * -0.05 || 1,
                      property: `translateY`
                    }
                  ]
                }
              ]}
            >
              <Img
                className="w-full relative block"
                fluid={event.galleryLowerLeft.childImageSharp.fluid}
                alt={event.title}
              />
            </Plx>
          )}

          <p
            className="event-page__description__content grid-end-6 sm:grid-end-10 grid-start-6 sm:grid-start-2 mt-32 b1 whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: event.detailsFilm
            }}
          ></p>
        </section>

        {event.videoLower && event.videoLower !== `` && (
          <>
            {(device === `desktop` && (
              <section className="grid">
                <div className="grid-end-8 grid-start-3 mb-12">
                  <div className="event-page__youtube w-full relative">
                    <div
                      className="w-full h-full absolute top-0 right-0 bottom-0 left-0"
                      dangerouslySetInnerHTML={{ __html: event.videoLower }}
                    ></div>
                  </div>
                </div>
              </section>
            )) || (
              <section className="w-full relative mb-12">
                <div className="event-page__youtube w-full relative">
                  <div
                    className="w-full h-full absolute top-0 right-0 bottom-0 left-0"
                    dangerouslySetInnerHTML={{ __html: event.videoLower }}
                  ></div>
                </div>
              </section>
            )}
          </>
        )}

        {event.detailsMusic && event.detailsMusic !== `` && (
          <section className="event-page__artist relative grid py-24">
            <div className="grid-end-4 sm:grid-end-10 grid-start-2 sm:grid-start-2">
              <div
                className="b1"
                dangerouslySetInnerHTML={{
                  __html: event.detailsMusic
                }}
              ></div>

              {event.detailsMusicImage &&
                event.detailsMusicImage.childImageSharp && (
                  <Img
                    className="w-full relative block"
                    fluid={event.detailsMusicImage.childImageSharp.fluid}
                    alt={event.title}
                  />
                )}

              {event.onSale && event.ticketUrl && event.ticketUrl !== `` && (
                <TicketButton
                  bordered
                  color="#000000"
                  className="mt-16"
                  ticketUrl={event.ticketUrl}
                />
              )}
            </div>

            {event.galleryLowerRight &&
              event.galleryLowerRight.childImageSharp && (
                <Plx
                  className="grid-end-6 sm:grid-end-12 grid-start-7 sm:grid-start-1"
                  parallaxData={[
                    {
                      start: `self`,
                      duration: parallaxDuration,
                      properties: [
                        {
                          startValue: 0,
                          endValue: appContext.windowWidth * -0.075 || 1,
                          property: `translateY`
                        }
                      ]
                    }
                  ]}
                >
                  <Img
                    className="w-full relative block"
                    fluid={event.galleryLowerRight.childImageSharp.fluid}
                    alt={event.title}
                  />
                </Plx>
              )}
          </section>
        )}

        {event.credits && event.credits !== `` && (
          <section className="event-page__credits relative grid py-24 text-center">
            <div
              className="grid-end-6 sm:grid-end-10 grid-start-4 sm:grid-start-2 relative flex flex-col items-center justify-center b1"
              dangerouslySetInnerHTML={{ __html: event.credits }}
            ></div>
          </section>
        )}
      </>
    );
  }
}

const EventLayout = ({ event, sideText }) => {
  const appContext = useContext(AppContext);

  return (
    <EventLayoutComponent
      appContext={appContext}
      event={event}
      sideText={sideText}
    />
  );
};

export default EventLayout;
